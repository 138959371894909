import { Helmet, HelmetProvider } from 'react-helmet-async';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import OfferPageLayout from '../components/Layout/OfferPageLayout';
import ProtectedLayout from '../components/Layout/ProtectedLayout';
import PublicLayout from '../components/Layout/PublicLayout';
import ProtectedNavbar from '../components/Navigation/Navbar';
import {
  AuthProvider,
  LoginCallback,
  Logout,
  LogoutCallback,
  RedirectOnAuth,
  RequireAuth,
} from '../features/Auth';
import Congratulations from '../features/Congratulations/Congratulations';
import { Dashboard } from '../features/Dashboard';
import { Documents } from '../features/Documents';
import { EntryPage, ExistingTreeAuthAccount } from '../features/Entry';
import { History } from '../features/History';
import { InsideForm } from '../features/InsideForm';
import MyLtEntryPage from '../features/InsideForm/MyLtEntryPage';
import { NotFound } from '../features/NotFound';
import Offers from '../features/Offers/Offers';
import PlaidLink from '../features/Plaid/PlaidLink';
import { Profile } from '../features/Profile';
import { Resources } from '../features/Resources';
import Signup from '../features/Signup/Signup';
import TreeAuthSignup from '../features/Signup/TreeAuthSignup';
import { useGetFeatureFlagsQuery } from './services/featuresFlags';

const App = (): JSX.Element => {
  const { isLoading: isLoadingFeatureFlags } = useGetFeatureFlagsQuery();
  if (isLoadingFeatureFlags) return <></>;

  return (
    <HelmetProvider>
      <div className="app">
        <Helmet titleTemplate="%s | SnapCap Business">
          <title>Small Business Financing and Resources</title>
        </Helmet>
        <Router basename="/app">
          <AuthProvider>
            <Routes>
              <Route element={<PublicLayout showFooter={true} showSignIn={true} />} path="/">
                <Route
                  index
                  element={
                    <RedirectOnAuth>
                      <EntryPage />
                    </RedirectOnAuth>
                  }
                />
                <Route element={<NotFound />} path="*" />
              </Route>
              <Route element={<PublicLayout />}>
                <Route element={<LoginCallback />} path="login/callback" />
                <Route element={<Logout />} path="logout" />
                <Route element={<LogoutCallback />} path="logout/callback" />
                <Route element={<PlaidLink />} path="/plaidLink" />
                <Route element={<Congratulations />} path="congratulations" />
              </Route>
              <Route element={<PublicLayout showFooter={true} />}>
                <Route element={<TreeAuthSignup />} path="existing-treeauth" />
                <Route element={<ExistingTreeAuthAccount />} path="signup/existing-treeauth" />
                <Route element={<NotFound />} path="congratulations/error" />
              </Route>
              <Route
                element={<PublicLayout showBackArrow={true} showFooter={true} showSignIn={true} />}
              >
                <Route element={<Signup />} path="signup/*"></Route>
              </Route>
              <Route element={<OfferPageLayout header={<ProtectedNavbar />} />}>
                <Route element={<Offers />} path="offers/*" />
              </Route>
              <Route
                element={
                  <RequireAuth>
                    <ProtectedLayout />
                  </RequireAuth>
                }
              >
                <Route element={<Navigate to={'/dashboard'} />} path="home" />
                <Route element={<InsideForm />} path="forms/:formId" />
                <Route element={<MyLtEntryPage />} path="mylt-entry" />
                <Route element={<Resources />} path="resources/*" />
                <Route element={<History />} path="history" />
                <Route element={<Profile />} path="profile" />
                <Route element={<Documents />} path="documents/:applicationId" />
                <Route element={<Documents />} path="documents" />
                <Route element={<Dashboard />} path="dashboard" />
              </Route>
            </Routes>
          </AuthProvider>
        </Router>
      </div>
    </HelmetProvider>
  );
};

export default hot(App);
